<template>




  <div>

    
<div class="w-full" id="Scroll">
      <vx-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :max-items="itemsPerPage"
            :data="Model"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <div class="flex flex-wrap-reverse items-center">
                <span v-if="queriedItems > 0" class="mr-2 text-xl"
                  >{{ $t("Total") }} :
                </span>
                <span v-if="queriedItems > 0" class="text-primary text-xl">
                  {{ queriedItems }}
                </span>
              </div>
            </div>

            <template slot="thead">
              <vs-th >{{ $t("Prescriptions") }}</vs-th>
              <vs-th >{{ $t("Date") }}</vs-th>
              <vs-th >{{ $t("Doctors") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      #{{ tr.Name }}                  
                      </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      <span :dir="$vs.rtl ? 'ltr' : 'ltr'">
                        {{ tr.DateFormatted}}
                    </span> 
                    </p>
                  </vs-td>
                  <vs-td>
                    <b-media vertical-align="center">
                      <template #aside>
                        <imageLazy
                      :imageStyle="{ 'border-radius': '15px','width': '30px','height':'30px'}"
                      :imageClass="'rounded-full'"
                      :src="baseURL + tr.DoctorImage"
                      placeHolderType="doctor"
                    />
                  </template>
          <p class="font-weight-bold d-block text-nowrap">
            {{ $t("Dr") }}. {{( tr.DoctorName )}}
          </p>

        </b-media>
                  </vs-td>
                  <vs-td>
                    
        <div class="text-nowrap">
        

        <!-- Dropdown -->
       
        <b-dropdown
          variant="link"
          toggle-class="p-0"
          no-caret
          :right="$store.state.appConfig.isRTL">

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item  @click="Download( data.item, index)">
            <feather-icon icon="DownloadIcon"  />
            <span class="align-middle ml-50">{{$t('Download')}}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
                  
    </vs-td>
               
  </vs-tr>
              
</tbody>
           
</template>
          </vs-table>
        
        </div>

      </vx-card>

    </div>
  <!-- Table Container Card -->

  <div class="vx-row"   v-if="Model.length > 0">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-center">
            <b-button   icon="icon-arrow-right"
          :icon-after="true" type="border" variant="primary"  @click="$router.push({ name: 'PrescriptionsList' })" style="!important; margin-left: 40%"> {{
              $t("SeeAllPrescriptions")
            }}</b-button>
          </div>
        </div>
      </div>
    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger"  @click="$router.go(-1)">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip } from 'bootstrap-vue';

export default {
  components:{
    imageLazy,
    BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip
  },
  data() {
    return {
      tableColumns:[
         { key: 'Prescriptions', label: this.$t("Prescriptions"), sortable: true },
         { key: 'Date', label: this.$t("Date"), sortable: true },
         { key: 'Doctors', label: this.$t("Doctors"), sortable: true },
         { key: 'Actions', label: this.$t("Actions") },
        
        ],
      baseURL: domain,
      Model: [],
    };
  },
  methods: {
    Download(item, index) {
      this.$store.commit("UPDATE_PrescriptionsList", item);
      console.log(index);
      this.$router.push({ name: "PrescriptionPDF" ,params:{ID:item.PatientReservationSessionID} });
    },
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    var Obj = {
      patientID: this.$store.state.AppActiveUser.Patient.ID,
    };
    this.$store
      .dispatch("patientList/SearchReservationSessionPrescriptions", Obj)
      .then((res) => {
        if (res.status == 200) {
          this.Model = res.data.Data;
                if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
  },
};
</script>
<style>
#RSPresciption .downloadCell {

  padding: 0px;
}
#RSPresciption table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#RSPresciption td,
#RSPresciption th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#RSPresciption th {
  border-top: none;
}

#RSPresciption th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#RSPresciption th:last-child {
  border-top-right-radius: 36px;
}
#RSPresciption td:first-child,
#RSPresciption th:first-child {
  border-left: none;
}
</style>

<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4ab6a2;
  color: white;
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}</style
>
<style>
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>
